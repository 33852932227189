.crashpad-container {
  position: relative;
}
.crashpad-container .crashpad-hover {
  position: absolute;
  z-index: 1;
}
.crashpad-container .crashpad-hover-top,
.crashpad-container .crashpad-hover-bottom {
  width: 100%;
  height: 10%;
  left: 0;
}
.crashpad-container .crashpad-hover-top {
  top: 0;
}
.crashpad-container .crashpad-hover-top:hover ~ .crashpad {
  transform: rotateX(-45deg);
}
.crashpad-container .crashpad-hover-top:hover ~ .crashpad .crashpad-top {
  transform: rotateX(65deg) translate3d(0, 0, -0.3vh);
}
.crashpad-container .crashpad-hover-bottom {
  bottom: 0;
}
.crashpad-container .crashpad-hover-bottom:hover ~ .crashpad {
  transform: rotateX(45deg);
}
.crashpad-container .crashpad-hover-bottom:hover ~ .crashpad .crashpad-bottom {
  transform: rotateX(-65deg) translate3d(0, 0, -0.3vh);
}
.crashpad-container .crashpad-hover-left,
.crashpad-container .crashpad-hover-right {
  width: 10%;
  height: 100%;
  top: 0;
}
.crashpad-container .crashpad-hover-left {
  left: 0;
}
.crashpad-container .crashpad-hover-left:hover ~ .crashpad {
  transform: rotateY(45deg);
}
.crashpad-container .crashpad-hover-left:hover ~ .crashpad .crashpad-left {
  transform: rotateY(-65deg) translate3d(0, 0, -0.3vh);
}
.crashpad-container .crashpad-hover-right {
  right: 0;
}
.crashpad-container .crashpad-hover-right:hover ~ .crashpad {
  transform: rotateY(-45deg);
}
.crashpad-container .crashpad-hover-right:hover ~ .crashpad .crashpad-right {
  transform: rotateY(65deg) translate3d(0, 0, -0.3vh);
}
.crashpad {
  display: flex;
  transform: rotateX(0) rotateY(0);
  transition: transform 0.2s ease-out;
  position: relative;
}
.crashpad-front {
  height: 40vh;
  width: 50vh;
  border-radius: 1vh;
  z-index: 1;
}
.crashpad-top,
.crashpad-bottom {
  border-radius: 1vh;
  height: 10vh;
  width: 50vh;
  transition: transform 0.2s ease-out;
  position: absolute;
  left: 0;
}
.crashpad-top {
  transform: rotateX(90deg);
  transform-origin: bottom;
  top: -10vh;
}
.crashpad-bottom {
  transform: rotateX(-90deg);
  transform-origin: top left;
  bottom: -10vh;
}
.crashpad-left,
.crashpad-right {
  border-radius: 1vh;
  height: 40vh;
  width: 10vh;
  transition: transform 0.2s ease-out;
  position: absolute;
  top: 0;
}
.crashpad-left {
  transform: rotateY(-90deg);
  transform-origin: top right;
  left: -10vh;
}
.crashpad-right {
  transform: rotateY(90deg);
  transform-origin: top left;
  right: -10vh;
}
.crashpad-layout::after,
.crashpad-top::after,
.crashpad-bottom::after {
  content: '';
  width: 2px;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  right: 50%;
}
.crashpad-layout::after {
  opacity: 0.1;
}
.crashpad-top::after,
.crashpad-bottom::after {
  width: 1px;
  opacity: 0.8;
}
.crashpad-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: 100%;
  overflow: hidden;
  border-radius: 1vh;
  z-index: 1;
}
.crashpad-accent,
.crashpad-accent-cover {
  width: 24vh;
  height: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: 200%;
  position: absolute;
}
@-moz-document url-prefix() {
  .crashpad-container .crashpad-hover-top:hover ~ .crashpad .crashpad-top {
    transform: rotateX(90deg);
  }
  .crashpad-container .crashpad-hover-bottom:hover ~ .crashpad .crashpad-bottom {
    transform: rotateX(-90deg);
  }
  .crashpad-container .crashpad-hover-left:hover ~ .crashpad .crashpad-left {
    transform: rotateY(-90deg);
  }
  .crashpad-container .crashpad-hover-right:hover ~ .crashpad .crashpad-right {
    transform: rotateY(90deg);
  }
  .crashpad-top,
  .crashpad-bottom,
  .crashpad-left,
  .crashpad-right {
    border-radius: 0;
    opacity: 0.6;
  }
  .crashpad-top,
  .crashpad-bottom {
    height: 5vh;
    width: 49.8vh;
    left: 0.2vh;
  }
  .crashpad-left,
  .crashpad-right {
    width: 5vh;
    height: 39.8vh;
    top: 0.2vh;
  }
  .crashpad-top {
    top: -4.8vh;
  }
  .crashpad-bottom {
    bottom: -4.8vh;
  }
  .crashpad-left {
    left: -4.8vh;
  }
  .crashpad-right {
    right: -4.8vh;
  }
}
[color] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  box-shadow: 0 0 1px rgba(255,255,255,0);
  overflow: hidden;
}
[color="black"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/black-large.jpg");
}
[color="baby-blue"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/baby-blue-large.jpg");
}
[color="blaze-orange"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/blaze-orange-large.jpg");
}
[color="brown"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/brown-large.jpg");
}
[color="burgundy"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/burgundy-large.jpg");
}
[color="charcoal-gray"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/charcoal-gray-large.jpg");
}
[color="coral"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/coral-large.jpg");
}
[color="dark-tan"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/dark-tan-large.jpg");
}
[color="desert-camo"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/desert-camo-large.jpg");
}
[color="desert-tiger-stripe"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/desert-tiger-stripe-large.jpg");
}
[color="digital-camo"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/digital-camo-large.jpg");
}
[color="duck-hunter-camo"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/duck-hunter-camo-large.jpg");
}
[color="forest-green"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/forest-green-large.jpg");
}
[color="fuscia"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/fuscia-large.jpg");
}
[color="golden-yellow"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/golden-yellow-large.jpg");
}
[color="grasshopper-green"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/grasshopper-green-large.jpg");
}
[color="ice-blue"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/ice-blue-large.jpg");
}
[color="kelly-green"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/kelly-green-large.jpg");
}
[color="marpat-camo"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/marpat-camo-large.jpg");
}
[color="mint-green"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/mint-green-large.jpg");
}
[color="navy"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/navy-large.jpg");
}
[color="neon-blue"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/neon-blue-large.jpg");
}
[color="neon-green"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/neon-green-large.jpg");
}
[color="neon-pink"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/neon-pink-large.jpg");
}
[color="neon-yellow"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/neon-yellow-large.jpg");
}
[color="olive-green"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/olive-green-large.jpg");
}
[color="orange"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/orange-large.jpg");
}
[color="peach"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/peach-large.jpg");
}
[color="plum"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/plum-large.jpg");
}
[color="purple"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/purple-large.jpg");
}
[color="red"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/red-large.jpg");
}
[color="royal-blue"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/royal-blue-large.jpg");
}
[color="rust"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/rust-large.jpg");
}
[color="sand-tan"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/sand-tan-large.jpg");
}
[color="silver"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/silver-large.jpg");
}
[color="teal"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/teal-large.jpg");
}
[color="white"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/white-large.jpg");
}
[color="woodland-camo"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/woodland-camo-large.jpg");
}
[color="yellow"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/yellow-large.jpg");
}
